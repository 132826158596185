import React from "react";
import "../../styles/header.css";

const HeaderMenu = ({ name, link, onNavOff }) => {
  return (
    <div className="header-menu">
      <a href={link} onClick={onNavOff}>{name}</a>
    </div>
  );
};

export default HeaderMenu;
