import React, { useRef, useState, useEffect } from "react";
import { IcosahedronGeometry } from "three";
import { useFrame } from "@react-three/fiber";

const Shape = () => {
  const shape = new IcosahedronGeometry(1);

  const [scaling, setScaling] = useState(2);
  const [position, setPosition] = useState([2,0,0])
  const [isHovering, setHovering] = useState(false);

  useEffect(() => {
    if(window.matchMedia("(max-width: 560px").matches){
      setScaling(1);
      setPosition([0,2.5,0]);
    } else {
      setScaling(1.5);
      setPosition([2,0,0])
    }
  }, [])

  useEffect(() => {
    
    const handleResize = () => {
      if(window.matchMedia("(max-width: 560px").matches){
        setScaling(1);
        setPosition([0,2.5,0]);
      } else {
        setScaling(1.5);
        setPosition([2,0,0])
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  const setHover = () => {
    setHovering(true);
  }

  const setNoHover = () => {
    setHovering(false);
  }


  shape.rotateY(45);
  shape.rotateZ(45);
  const mesh = useRef();

  useFrame(() => {
    mesh.current.rotation.x += 0.01;
    mesh.current.rotation.y += 0.01;

    if(isHovering) {
      mesh.current.rotation.x += 0.03;
      mesh.current.rotation.y += 0.03;
    }
  });

  return (
    <>
    <mesh geometry={shape} ref={mesh} scale={scaling} position={position} onPointerOver={setHover} onPointerDown={setHover} onPointerUp={setNoHover} onPointerLeave={setNoHover}>
      <meshNormalMaterial wireframe={true} wireframeLinewidth={1} />
    </mesh>
    </>
  );
};

export default Shape;
