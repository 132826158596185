import ProjectDisplayCard from "./ProjectDisplayCard";
import SetVisibility from "../functionality/SetVisiblility";
import Videos from "./Videos";

import { useRef, useState } from "react";

export default function Projects(){
  const [isVisible, setIsVisible] = useState(false);
  const [showVid, setShowVid] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [videoText, setVideoText] = useState("");
  const [videoTitle, setVideoTitle] = useState("");

  const containerRef = useRef(null);

  return (
    <>
    <div id="projects"></div>
    <div className={`projects-video-container ${showVid ? " scroll-to-video" : ""}`}>
      <div className={`video-container${showVid ? "-show-vid" : ""}`}> 
      <Videos showVid={showVid} videoLink={videoLink} videoText={videoText} videoTitle={videoTitle} setShowVid={setShowVid} setVideoLink={setVideoLink}/>
      </div> 
    <div className={`projects-container${showVid ? " show-vid" : ""}`}>

    <SetVisibility containerRef={containerRef} isvisible={isVisible} setIsVisible={setIsVisible} reset={false}/>    

  <h1>{"<Projects />"}</h1>

<div className={`projects`} ref={containerRef}>
  <ProjectDisplayCard
    className="projects-item-1"
    title={"This Website!"}
    text={"Using HTML, CSS, Javascript, React, Three JS & Blender I created this 3D interactive website."}
    date={"late 2022"}
    link={"#intro"} 

    isVisible={isVisible}
    setShowVid={setShowVid}
    setVideoText={setVideoText}
    setVideoTitle={setVideoTitle}
    
  />

  <ProjectDisplayCard
    className="projects-item-2"
    title={"Unity Boomerang Game"}
    text={"A Multiplayer sandbox environment created with Photon Network & a custom made prefab spawner."}
    date={"2022"} 
    link={"#projects"} 

    isVisible={isVisible}
    setShowVid={setShowVid}
    setVideoText={setVideoText}
    setVideoTitle={setVideoTitle}
  />

  <ProjectDisplayCard
    className="projects-item-3"
    title={"Invoice Initializer"}
    text={"Enter client ID & price; \n\nThe program fetches client info from an excel file, calculates TAX and sets up your Word invoice document with all necessary info filled in."}
    date={"late 2022"} 
    link={"https://github.com/ThomasVRAudio/invoice-initializer"} 
    setVideoLink={""}
    target={"_blank"}

    isVisible={isVisible}
    setShowVid={setShowVid}
    setVideoText={setVideoText}
    setVideoTitle={setVideoTitle}
  />

  <ProjectDisplayCard
    className="projects-item-4"
    title={"Sky Arena - VR Sandbox"}
    text={"The first time I started digging deeper into game development, 3D Blender art & shaders. It has rope swinging and gun shooting, what more do you want?"}
    date={"2020"}
    link={"#projects"} 
    setVideoLink={setVideoLink} 
    videoLink={"https://www.youtube.com/embed/DI6iHiaE9qQ"}

    isVisible={isVisible}
    setShowVid={setShowVid}
    setVideoText={setVideoText}
    setVideoTitle={setVideoTitle}
    />

  <ProjectDisplayCard
    className="projects-item-5"
    title={"Wwise Audio Implementation"}
    text={`Created and Implemented audio into \n"The Explorer" Unity Sample Project using Wwise. Added room reverberation, ground checking for the correct footstep sound and coupled audio to animation`}
    date={"early 2022"}
    link={"#projects"} 
    setVideoLink={setVideoLink} 
    videoLink={"https://www.youtube.com/embed/z5GUetjL9qs"}

    isVisible={isVisible}
    setShowVid={setShowVid}
    setVideoText={setVideoText}
    setVideoTitle={setVideoTitle}
    />

  <ProjectDisplayCard
    className="projects-item-6"
    title={"Thomas VR Audio"}
    text={"My audio portfolio website has been created using HTML, CSS, Javascript & React. This website shows off my Sound Design & Compositions for games"}
    date={"updated late 2022"}
    link={'http://www.thomasvraudio.com'} 
    target={"_blank"}

    isVisible={isVisible}
    setShowVid={setShowVid}
    setVideoText={setVideoText}
    setVideoTitle={setVideoTitle}
    />

</div>
</div>
</div>
</>

  )
}