export default function ProjectDisplayCard({ title, text, date, link, setVideoLink, videoLink, target, isVisible, setShowVid, setVideoText, setVideoTitle}) {

  const callbackFunction = () => {
    setVideoLink(videoLink);
    setShowVid(true);
    setVideoText(text);
    setVideoTitle(title)
  }

  return (
    <>
      <div className={`hover-card project-card fade-in${isVisible ? "-appear" : ""}`} onClick={callbackFunction}>
      <a href={link} target={target}>
        <div className="top-card">
        {<h3>{title}</h3>}
        </div>      
        <div className="project-content">

          <p>{text}</p>
          
          <div className="project-date">{`- ${date}`}</div>
        </div>
        </a>
      </div>
    </>
  );
}
