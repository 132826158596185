import { useEffect, useState } from "react";

export default function TextCard({ text, isShiny }) {
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [globalCoords, setGlobalCoords] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleWindowMouseMove = (event) => {
      setGlobalCoords({
        x: event.screenX,
        y: event.screenY,
      });
    };
    window.addEventListener("mousemove", handleWindowMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove);
    };
  }, []);

  const handleMouseMove = (event) => {
    setCoords({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
  };

  return (
    <div className="card-container">
    <div
      className="card"
      onMouseMove={handleMouseMove}
      style={{
        "--xPosition": coords.x + "px",
        "--yPosition": coords.y + "px",
        background: isShiny
          ? "radial-gradient(circle 800px at var(--xPosition) var(--yPosition), rgba(255, 255, 255, 0.06), transparent 40%)"
          : "transparent",
      }}
    >
      {text}
    </div>
    </div>
  );
}

//{coords.x} {coords.y}
