import React from "react";
import HeaderMenu from "./HeaderMenu";

const MobileHeaderMenu = ({ onNavOff }) => {
  return (
    <div className="mobile-header-menu">
      <HeaderMenu name={"Home"} link={"#intro"} onNavOff={onNavOff} />
      <HeaderMenu
        name={"Projects"}
        link={"#projects"}
        onNavOff={onNavOff}
      />
      <HeaderMenu
        name={"About"}
        link={"#about"}
        onNavOff={onNavOff}
      />
      <HeaderMenu
        name={"Contact"}
        link={"#contact"}
        onNavOff={onNavOff}
      />
    </div>
  );
};

export default MobileHeaderMenu;