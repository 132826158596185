import { useState} from "react";
import { useFrame } from "@react-three/fiber";

export default function SetRotation(){
  let [currentRotation, setCurrentRotation] = useState(0);
  
  useFrame(() => {
    setCurrentRotation={setCurrentRotation}
    currentRotation={currentRotation}
  });
};