import { useEffect, useState } from "react";

export default function SetVisibility({containerRef, setIsVisible, reset}){
  
  const [rootMargin, setRootMargin] = useState(0);
  const [allowReset, setAllowReset] = useState(true);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    if(allowReset) {
      setIsVisible(entry.isIntersecting);
    } else { 
      if(entry.isIntersecting){ setIsVisible(true); }
    }
  }

  useEffect(() => {
    setAllowReset(reset);
  }, [reset])

  let options = {}

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);

    options = {
      root: null,
      rootMargin: `${rootMargin}px 1000px 1000px 0px`,
      threshold: 1
    }

    if(window.matchMedia("(max-width: 500px").matches) {
      setRootMargin(1000)
    } else {
      setRootMargin(0)
    }

    

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current)
    }
  }, [containerRef, options])
}