export default function Videos ({videoLink, showVid, videoText, videoTitle, setShowVid, setVideoLink}) {
  
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const callbackFunction = async event => {
    setShowVid(false)
    
    await delay(1200);

    setVideoLink("");
  }



  return (
    <>
    <div className={`videos${showVid ? "-show-vid" : ""}`}>
     <iframe src={`${videoLink}?autoplay=1`}  title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <div className="video-right-section">
    <div className="video-text-container">
      <h1>{videoTitle}</h1>
    <div className="video-text"><p>{videoText}</p></div>
    </div>
    <div className="return-projects" onClick={callbackFunction}> <p>{"->"}</p> </div>
    </div>
    </> 
    )}


