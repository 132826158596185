import { Canvas } from "@react-three/fiber";
import Shape from "./components/Models/Shape";
import Front from "./Front";
import "./styles/general.css";
import SetRotation from "./functionality/SetRotation";

function App() {
  return (
    <>
      <Front />
      <Canvas frameloop="always" className="canvas">
        <directionalLight position={[-2, 5, 2]} intensity={1} />
        <ambientLight intensity={0.8} />
        <Shape />
        <SetRotation />
      </Canvas>
    </>
  );
}

export default App;
