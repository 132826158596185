import React from "react";
import "../../styles/header.css";
import HeaderMenu from "./HeaderMenu";
import HeaderSocial from "./HeaderSocial";

const Header = () => {
  return (
    <header className="header-bar">
      <div className="header-menu">
        <HeaderMenu name={"Home"} link={"#intro"} />
        <HeaderMenu name={"Projects"} link={"#projects"} />
        <HeaderMenu name={"About"} link={"#about"} />
        <HeaderMenu name={"Contact"} link={"#contact"} />
      </div>
      <div className="header-social-media">
      </div>
    </header>
  );
};

export default Header;
